
import Modal from '@/components/actions/Modal.vue'
import { InputSelect, InputText } from '@/components/inputs'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { FormPage } from '@/mixins'
import EmailTemplate from 'tradingmate-modules/src/models/api/admin/email-templates/EmailTemplate'
import EmailTemplateCreateDto from 'tradingmate-modules/src/models/api/admin/email-templates/EmailTemplateCreate'
import EmailTemplateCategory from 'tradingmate-modules/src/models/api/admin/email-templates/EmailTemplateCategory'
import { HttpError, Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import { DropdownListItem } from 'tradingmate-modules/src/forms'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader,
    InputText,
    InputSelect,
    Modal
  }
})

export default class EmailTemplates extends Mixins(FormPage) {
  private emailTemplates: EmailTemplate[] | null = null
  private httpErrors: HttpError[] | null = null;
  // private loading = false

  private showNewTemplateModel = false

  private categories: DropdownListItem[] = [
    { Key: EmailTemplateCategory[1], Label: 'Sign up', Value: EmailTemplateCategory.SignUp },
    { Key: EmailTemplateCategory[2], Label: 'Membership Activation', Value: EmailTemplateCategory.MembershipActivation },
    { Key: EmailTemplateCategory[3], Label: 'Membership Renewal', Value: EmailTemplateCategory.MembershipRenewal },
    { Key: EmailTemplateCategory[4], Label: 'Quote Requests', Value: EmailTemplateCategory.QuoteRequests },
    { Key: EmailTemplateCategory[5], Label: 'Contact Us', Value: EmailTemplateCategory.ContactUs },
    { Key: EmailTemplateCategory[4], Label: 'Other', Value: EmailTemplateCategory.Other }
  ]

  get signUpEmailTemplate (): EmailTemplate[] | undefined {
    return this.emailTemplates?.filter(x => x.Category === EmailTemplateCategory.SignUp)
  }

  get membershipActivateEmailTemplates (): EmailTemplate[] | undefined {
    return this.emailTemplates?.filter(x => x.Category === EmailTemplateCategory.MembershipActivation)
  }

  get membershipRenewalEmailTemplate (): EmailTemplate[] | undefined {
    return this.emailTemplates?.filter(x => x.Category === EmailTemplateCategory.MembershipRenewal)
  }

  get quoteRequestEmailTemplates (): EmailTemplate[] | undefined {
    return this.emailTemplates?.filter(x => x.Category === EmailTemplateCategory.QuoteRequests)
  }

  get contactUsEmailTemplates (): EmailTemplate[] | undefined {
    return this.emailTemplates?.filter(x => x.Category === EmailTemplateCategory.ContactUs)
  }

  get otherEmailTemplate (): EmailTemplate[] | undefined {
    return this.emailTemplates?.filter(x => x.Category === EmailTemplateCategory.Other)
  }

  get customEmailTemplate (): EmailTemplate[] | undefined {
    return this.emailTemplates?.filter(x => x.Category === EmailTemplateCategory.Custom)
  }

  mounted (): void {
    this.loading = true
    Services.API.Admin.Emails.GetEmailTemplates()
      .then((temps) => { this.emailTemplates = temps })
      .catch((errors) => { this.httpErrors = errors })
      .finally(() => { this.loading = false })
  }

  handleEmailTemplateClick (emailTemplateId: string): void {
    this.$router.push({ path: '/admin/email/templates/edit', query: { id: emailTemplateId } })
  }

  private creatingTemplate = false
  private dto: EmailTemplateCreateDto = { TemplateName: '', Category: EmailTemplateCategory.Custom }
  onCreateTemplate (): void {
    Services.API.Admin.Emails.CreateTemplate(this.dto)
      .then((response) => {
        this.$router.push({ name: 'Email Template Edit', query: { id: response.EmailTemplateId } })
      })
      .catch((errors) => {
        console.log(errors)
      })
      .finally(() => {
        this.creatingTemplate = false
      })
  }
}

