enum EmailTemplateCategory {
    None = 0,
    SignUp = 1,
    MembershipActivation = 2,
    MembershipRenewal = 3,
    QuoteRequests = 4,
    ContactUs = 5,
    Other = 6,
    Custom = 7,
    Meta = 1000
}

export default EmailTemplateCategory;
